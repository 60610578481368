import React, { useEffect, useCallback, useState, useMemo } from 'react';
import {
  Typography,
  Box,
  AlertTitle,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { SaveIcon } from 'components/common/Icon';
import { styled } from '@mui/material/styles';
import { FileRejection } from 'react-dropzone';
import { Alert } from '@mui/lab';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { UploadFile } from 'components/common';
import Skeleton from '@mui/lab/Skeleton';
import { ProgressTrackerStatus } from 'services/account';
import {
  useDocumentsByAccount,
  useDocumentCategories,
  useCreateDocument,
  useDeleteDocument,
  useUpdateCompanySetupStateAsync,
} from 'hooks/api';
import {
  findCategoryByName,
  getDocumentAvailableForCategory,
} from 'helpers/documents';
import { queryClient } from 'states/reactQueryClient';

import { TableView } from 'components/AdminProgressTracker/AccountVerification/TableView';
import { DocumentYear } from 'services/documentTypes';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import {
  CHURN_ZERO_EVENT_NAMES,
  DEFAULT_ALLOWED_FILES,
} from 'constants/common';
import { useTranslation } from 'react-i18next';
import { MAX_DOCUMENT_SIZE_BYTES } from 'constants/documents';
import { fileUploadErrorHandler, nameLengthValidator } from 'helpers/common';

const ButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'end',
}));

interface IFile extends File {
  id: string;
  title: string;
  status: string;
  statusReason: string;
}

type TProps = {
  accountId: string | undefined;
  handleContinue?: (skipDocument: boolean) => void;
  isLoading?: boolean;
  categoryData: {
    category: string;
    subcategory: string;
    department: string;
  };
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  currentStatus?: ProgressTrackerStatus;
  defaultYear?: DocumentYear;
  comment?: string;
  multiple?: boolean;
  supportedFileHelperText?: string;
  accept?: string;
  optional?: boolean;
  skipLabel?: string;
};

const documentUploadLimit = 10;
const progressTrackerDocumentLimitPerStep = 100;

export const UploadGovID = ({
  accountId,
  handleContinue,
  isLoading = false,
  categoryData,
  title,
  subtitle,
  currentStatus,
  defaultYear = new Date().getFullYear(),
  comment = '',
  multiple = true,
  supportedFileHelperText,
  accept = DEFAULT_ALLOWED_FILES,
  optional = false,
  skipLabel,
}: TProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);
  const [skipDocument, setSkipDocument] = useState(false);
  const { categories, refetch: refetchCategories } = useDocumentCategories();
  const skipLabelWithDefault = useMemo(
    () => skipLabel ?? t('progressTracker.accountVerification.skipDocument'),
    [skipLabel, t],
  );

  const {
    isLoading: loadingDocuments,
    refetch: refetchDocuments,
    documents: documentData,
  } = useDocumentsByAccount(accountId ?? '', {
    accountId,
    page: 1,
    size: progressTrackerDocumentLimitPerStep,
  });

  const kycDocuments = getDocumentAvailableForCategory({
    documents: documentData,
    category: categoryData.category,
    subcategory: categoryData.subcategory,
    department: categoryData.department,
  });

  useEffect(() => {
    refetchCategories();
    refetchDocuments();
  }, [refetchCategories, refetchDocuments]);

  const documentCategory =
    findCategoryByName({
      categories,
      name: categoryData.category,
      subcategory: categoryData.subcategory,
      department: categoryData.department,
    }) ?? '';

  const { mutateAsync: createDocument, isLoading: isDocumentCreating } =
    useCreateDocument({
      onSuccess: () => {
        sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_UPLOAD);
        return queryClient.invalidateQueries([
          'documents',
          'accountId',
          accountId,
        ]);
      },
    });

  const { mutateAsync: deleteDocument, isLoading: isDocumentDeleting } =
    useDeleteDocument({
      onSuccess: () =>
        queryClient.invalidateQueries(['documents', 'accountId', accountId]),
    });
  const {
    updateCompanySetupStateAsync,
    isLoading: isCompanySetupStateUpdating,
  } = useUpdateCompanySetupStateAsync();

  const isSkipDocument = optional && skipDocument;
  const hasUploadedKYCDocuments = kycDocuments && kycDocuments.length > 0;

  const onSubmit = () => {
    // If optional is true, then we can continue without uploading any documents
    // If optional is false, then we need to upload at least one document
    if ((isSkipDocument || hasUploadedKYCDocuments) && handleContinue) {
      if (hasUploadedKYCDocuments) {
        updateCompanySetupStateAsync({
          lastYearTaxReturn: true,
        });
      }
      handleContinue(isSkipDocument);
    } else {
      setError(new Error(t('progressTracker.error.pleaseUploadAndRetry')));
    }
  };

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      const totalNoOfFiles = kycDocuments.length + acceptedFiles.length;
      if (acceptedFiles.length > documentUploadLimit) {
        setError(new Error(t('progressTracker.error.tooManyFile')));
      } else if (totalNoOfFiles > progressTrackerDocumentLimitPerStep) {
        setError(new Error(t('progressTracker.error.maxFileLimit10')));
      } else if (accountId) {
        acceptedFiles.forEach((file) =>
          createDocument({
            form: {
              accountId,
              file,
              title: file.name,
              year: defaultYear,
              documentCategoryId: documentCategory,
            },
          }),
        );
      }
    },
    [kycDocuments, documentCategory, accountId, createDocument],
  );

  const isStepCompleted = useMemo(
    () => currentStatus === ProgressTrackerStatus.Completed,
    [currentStatus],
  );
  const isStepRejected = useMemo(
    () => currentStatus === ProgressTrackerStatus.Rejected,
    [currentStatus],
  );

  const isInCustomerStage = useMemo(
    () =>
      currentStatus !== ProgressTrackerStatus.InProgress &&
      currentStatus !== ProgressTrackerStatus.Completed,
    [currentStatus],
  );

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileUploadErrorHandler(fileRejections, setError);
  }, []);

  const onDrop = useCallback(() => {
    setError(null);
  }, []);

  const onRemoveFile = async (fileToRemove: IFile) => {
    if (fileToRemove.id) {
      await deleteDocument(fileToRemove.id);
    }
  };

  const getSkeleton = () =>
    ['1'].map((index) => (
      <Typography component="div" key={index} variant="h2">
        <Skeleton animation="wave" />
      </Typography>
    ));

  const loadFileList = () =>
    isDocumentCreating ||
    isDocumentDeleting ||
    loadingDocuments ||
    isCompanySetupStateUpdating ? (
      getSkeleton()
    ) : (
      <TableView
        isLoading={false}
        documents={kycDocuments}
        allowFileDelete={!isStepCompleted}
        onDelete={onRemoveFile}
        showUploadFile={false}
        hasCompleteCTA={false}
        onUpload={() => null}
        onComplete={() => null}
        allowStepReject={false}
        onReject={() => null}
      />
    );

  return (
    <>
      {title && (
        <Typography variant="h5B" component="h5">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          sx={{ opacity: '0.7' }}
          mt={2}
          variant="body1"
          component="p"
        >
          {subtitle}
        </Typography>
      )}
      {error && (
        <Box mt={1}>
          <Alert severity="warning">{error.message}</Alert>
        </Box>
      )}
      {isStepRejected && (
        <Box mt={1} mb={1}>
          <Alert severity="warning">
            <AlertTitle>Action required</AlertTitle>
            {comment || 'Please reupload the correct files'}
          </Alert>
        </Box>
      )}
      <UploadFile
        supportedFileHelperText={supportedFileHelperText}
        uploadProps={{
          onDrop,
          onDropAccepted,
          onDropRejected,
          accept,
          maxSize: MAX_DOCUMENT_SIZE_BYTES,
          multiple,
          noClick: true,
          noKeyboard: true,
          validator: nameLengthValidator,
        }}
      />
      {loadFileList()}
      {optional && (
        <FormControlLabel
          control={
            <Checkbox
              name="checked"
              checked={skipDocument}
              onClick={() => setSkipDocument(!skipDocument)}
            />
          }
          label={skipLabelWithDefault}
        />
      )}

      {isInCustomerStage && handleContinue && (
        <ButtonContainer>
          <FormationsTertiaryButton
            isLoading={isLoading}
            endIcon={<SaveIcon />}
            loadingPosition="end"
            size="large"
            onClick={onSubmit}
            data-testid="save-n-continue-btn"
          >
            Save and Continue
          </FormationsTertiaryButton>
        </ButtonContainer>
      )}
    </>
  );
};
